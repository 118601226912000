/* eslint-disable max-lines */

import {Suspense} from 'react';

import {AddToCart} from 'Component/Product/Product.component';
import {ProductType} from 'Component/Product/Product.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import {TextPlaceHolderLength} from 'Component/TextPlaceholder/TextPlaceholder.config';
import {CategoryPageLayout} from 'Route/CategoryPage/CategoryPage.config';
import {ProductErpPrice} from 'SourceComponent/Product/Product.type';
import {
    ProductActionsComponent as SourceProductActionsComponent,
} from 'SourceComponent/ProductActions/ProductActions.component';
import {ReactElement} from 'Type/Common.type';
import {isSignedIn} from 'Util/Auth/IsSignedIn';
import {isCrawler, isSSR} from 'Util/Browser';
import {getErpPriceValue} from 'Util/ErpPrice';
import {formatPrice} from 'Util/Price';

import './ProductActions.override.style';

/** @namespace Steinkrueger/Component/ProductActions/Component */
export class ProductActionsComponent extends SourceProductActionsComponent {
    renderAddToCartActionBlock(): ReactElement {
        return (
            <div
              block="ProductActions"
              elem="AddToCartWrapper"
              mods={ { isPrerendered: isSSR() || isCrawler() } }
            >
                <div>
                    <div block="ProductActions" elem="AddToCartLabel">{ __('Quantity/Unit:') }</div>
                    { this.renderQuantityChanger() }
                </div>
                { this.renderAddToCartButton() }
            </div>
        );
    }

    renderAddToCartMobile(): ReactElement {
        return (
            <div
              block="ProductActions"
              elem="AddToCartFixed"
              mods={ { isPrerendered: isSSR() || isCrawler() } }
            >
                { this.renderQuantityChanger() }
                { this.renderAddToCartButton() }
            </div>
        );
    }

    renderAddToCartButton(layout = CategoryPageLayout.GRID): ReactElement {
        const {
            addToCart,
            inStock,
            quantity,
            getActiveProduct,
            updateSelectedValues,
            erpPrice = '',
        } = this.props;

        const isPriceNull = !getErpPriceValue(erpPrice);
        const isDisabled = !inStock || isPriceNull;

        return (
            <Suspense fallback={ null }>
                <AddToCart
                  mix={ { block: this.className, elem: 'AddToCart' } }
                  addToCart={ addToCart }
                  isDisabled={ isDisabled }
                  isIconEnabled
                  layout={ layout }
                  updateSelectedValues={ updateSelectedValues }
                  quantity={ quantity }
                  product={ getActiveProduct() }
                />
            </Suspense>
        );
    }

    renderProductPriceScope(): ReactElement {
        const { productErpPrices } = this.props;

        if (!productErpPrices || !productErpPrices.length) {
            return (
                <div block="ProductScope" elem="Loading">
                    <TextPlaceholder length={ TextPlaceHolderLength.MEDIUM } />
                </div>
            );
        }

        return (
            <div block="ProductScope">
                <div block="ProductScope" elem="Label">{ __('Product scope:') }</div>
                <div block="ProductScope" elem="List">
                    { productErpPrices.map(this.renderProductPriceScopeItem.bind(this)) }
                </div>
            </div>
        );
    }

    renderProductPriceScopeItem(item: ProductErpPrice): ReactElement {
        const {
            changePrice,
            selectedProductScope,
        } = this.props;

        const {
            price_type,
            label,
        } = item;

        if (!changePrice) {
            return null;
        }

        const isActive = selectedProductScope === price_type;

        return (
            <button
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ (event) => changePrice(price_type, event) }
              block="Button"
              elem="ProductScope"
              mods={ {isActive} }
              key={ price_type }
            >
                <span>{ __(label) }</span>
            </button>
        );
    }

    renderDesktop(): ReactElement {
        const isLogged = isSignedIn();

        if (!isLogged) {
            return (
                <>
                    { this.renderShortDescription() }
                </>
            );
        }

        return (
            <>
                { this.renderSku() }
                { this.renderShortDescription() }
                { this.renderConfigurableOptions() }
                { this.renderGroupedOptions() }
                { this.renderDownloadableSamples() }
                { this.renderDownloadableLinks() }
                { this.renderProductPriceScope() }
                { this.renderErpPrice() }
                { this.renderBreakOpenMessage() }
                { this.renderProductAlerts() }
                { this.renderCustomAndBundleOptions() }
                { this.renderAddToCartActionBlock() }
            </>
        );
    }

    renderErpPrice(): ReactElement {
        const {
            erpPrice = '',
            product: { prices, attributes },
            selectedProduct,
            isProductScopeSelectedByUser,
            selectedProductScope,
            productErpPrices,
        } = this.props;
        const isPriceLoaded = selectedProduct ? selectedProduct.prices?.kg_price : prices?.kg_price;
        const isPriceNull = !getErpPriceValue(erpPrice);

        if (isPriceNull || !erpPrice) {
            return null;
        }

        if (!isPriceLoaded) {
            return (
                <div block="ProductScope" elem="Loading">
                    <TextPlaceholder length={ TextPlaceHolderLength.MEDIUM } />
                </div>
            );
        }

        if (!isProductScopeSelectedByUser) {
            const productPrices = selectedProduct ? selectedProduct.prices : prices;
            const selectedProductAttributes = selectedProduct ? selectedProduct.attributes : attributes;

            const defaultPriceUnitValue = selectedProductAttributes
                ? selectedProductAttributes.default_price_unit.attribute_value : '';

            if (defaultPriceUnitValue) {
                const defaultPriceUnitLabel = defaultPriceUnitValue && selectedProductAttributes
                    ? selectedProductAttributes.default_price_unit
                        .attribute_options[defaultPriceUnitValue].label : '';
                const defaultLabel = selectedProductAttributes
                    // eslint-disable-next-line max-len
                    && selectedProductAttributes[`${defaultPriceUnitLabel}_unit` as keyof typeof selectedProductAttributes];
                const labelToShow = defaultLabel?.attribute_options[defaultLabel.attribute_value].label
                    || defaultPriceUnitLabel;

                const priceValue = productPrices
                    && productPrices[`${defaultPriceUnitLabel}_price` as keyof typeof productPrices];

                return (
                    <div block="ProductActions" elem="PriceWrapper">
                        <div block="ProductPrice">
                            { labelToShow
                                // eslint-disable-next-line max-len
                                && (
                                    <span block="ProductPrice" elem="ExclTaxLabel">
                                        <span>{ __(' per ') + labelToShow }</span>
                                        <span>
                                            { __('excl. VAT') }
                                        </span>
                                    </span>
                                ) }
                            <span block="ProductPrice" elem="Price">
                                <span block="ProductPrice" elem="PriceValue">
                                    { formatPrice(Number(priceValue)) }
                                </span>
                            </span>
                        </div>
                    </div>
                );
            }
        }

        const selectedProductType = productErpPrices?.filter((item) => (item.price_type === selectedProductScope));
        const selectedPriceLabel = selectedProductType && selectedProductType[0].label;

        return (
            <div block="ProductActions" elem="PriceWrapper">
                <div block="ProductPrice">
                    <span block="ProductPrice" elem="ExclTaxLabel">
                        <span>{ __(' per ') + selectedPriceLabel }</span>
                        <span>
                            { __('excl. VAT') }
                        </span>
                    </span>
                    <span block="ProductPrice" elem="Price">
                        <span block="ProductPrice" elem="PriceValue">
                            { erpPrice }
                        </span>
                    </span>
                </div>
            </div>
        );
    }

    renderSku(): ReactElement {
        const { getActiveProduct } = this.props;
        const { sku } = getActiveProduct();

        return <div block={ this.className } elem="Sku" itemProp="sku">{ __('SKU: %s', sku) }</div>;
    }

    renderBreakOpenMessage(): ReactElement {
        const { product, erpPrice, selectedProduct } = this.props;

        if (!erpPrice) {
            return null;
        }

        const attributes = selectedProduct ? selectedProduct.attributes : product.attributes;
        const attributeValue = attributes && attributes.break_open ? attributes.break_open.attribute_value : '';
        const message = attributeValue === '1' ? __('container is broken off')
            : __('container is originally sealed');

        return (
            <div block="BreakOpen">
                { message }
            </div>
        );
    }

    renderMobile(): ReactElement {
        const {product: {type_id: type}} = this.props;
        const isWithoutPriceTotal = type === ProductType.GROUPED;
        const isLogged = isSignedIn();

        if (!isLogged) {
            return (
                <>
                    { this.renderShortDescription() }
                </>
            );
        }

        return (
            <>
                { this.renderSku() }
                <div block="ProductActions" elem="ActionsWrapper" mods={ { isWithoutPriceTotal } }>
                    { this.renderProductPriceScope() }
                    { this.renderErpPrice() }
                    { this.renderBreakOpenMessage() }
                    { this.renderCustomAndBundleOptions() }
                </div>
                { this.renderBrand(true) }
                { this.renderShortDescription() }
                { this.renderProductAlerts() }
                { this.renderConfigurableOptions() }
                { this.renderGroupedOptions() }
                { this.renderDownloadableSamples() }
                { this.renderDownloadableLinks() }
                { this.renderAddToCartMobile() }
            </>
        );
    }
}

export default ProductActionsComponent;
